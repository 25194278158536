.calculatorContainer {
    width: 800px;
    height: 480px;
    margin: 50px auto;
    padding: 40px; 
    background-color: #fcedd0;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 38px;
    font-weight: bold;
    color: #1a8977 !important;
}

.subtitle {
    margin-top: 12px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 38px;
    font-weight: bold;
    color: #1a8977 !important;
}

.totalPrice {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 20px;
    font-size: 50px;
    color: black !important;
}

.monthlyPrice {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 20px;
    font-size: 1.2em;
    color: black !important;
}

.sliderContainer {
    margin: 20px 0;
}

.sliderLabel {
    font-family: Arial, Helvetica, sans-serif;
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 20px;
    color: black !important;
}

.valueDisplay {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
    color: black !important;
}

.inline{
    display: inline-flex;
}


  .Disclaimer{
    font-family: 'Times New Roman', Times, serif; 
    font-style: italic; 
    color: black !important;
  }
